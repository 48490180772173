<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="transparent"
      opacity="0.85"
      blur="2px"
      rounded="sm"
      v-if="is_sup"
    >
      <b-table small :fields="fields" :items="is_sup ? items : []" responsive striped>
        <!-- A virtual column -->
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <!-- A custom formatted column -->
        <template #cell(name)="data">
          {{ data.value.first + " - " + data.value.last }}
        </template>

        <!-- A custom formatted column -->
        <template #cell(Popularity)="data">
          <b-progress
            :value="data.value.value"
            max="100"
            :variant="data.value.variant"
            striped
          />
        </template>

        <template #cell(order_status)="data">
          <b-badge pill :variant="data.value.variant">
            {{ data.value.status }}
          </b-badge>
        </template>

        <!-- A virtual composite column -->
        <template #cell(price)="data">
          {{ "$" + data.value }}
        </template>

        <!-- Optional default data cell scoped slot -->
        <template #cell()="data">
          {{ data.value }}
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="rows"
      />
    </b-overlay>
    <div v-else class="text-container">
      <b style="color: red;">Only for Super Admin</b>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BOverlay,
  BProgress,
} from "bootstrap-vue";
import { mapState, mapActions, mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";

export default {
  name: "Memeber",
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BProgress,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        "index",
        // A column that needs custom formatting
        { key: "dtm_id", label: "DTM ID" },
        { key: "dtm_profile.fullName", label: "FullName" },
        { key: "dtm_profile.familyType", label: "Family Type" },
        { key: "dtm_profile.familyName", label: "Family Name" },
        { key: "dtm_profile.dateOfBirth", label: "Date of Birth" },
        // { key: "dtm_profile.email", label: "Email" },
        { key: "dtm_profile.mobile1", label: "Primery Number" },
        { key: "dtm_profile.mobile2", label: "Alternative Number" },
        // { key: "dtm_profile.gender", label: "Gender" },
        // { key: "dtm_profile.religion", label: "Religion" },
        // { key: "dtm_profile.occupation", label: "Occupation" },
        // { key: "dtm_profile.fbLink", label: "Facebook" },
        // { key: "dtm_profile.presentAddr", label: "Present Address" },
        // { key: "dtm_profile.permanentAddr", label: "Permanent Address" },
        // { key: "reference", label: "Reference" },
        { key: "status", label: "Status" },
        "created",
        // A regular column
        { key: "is_sms_send", label: "SMS Status" },
        { key: "is_bank_send", label: "Bank Status" },
        // A regular column
        { key: "free_month", label: "Free Month" },
        // A virtual column made up from two fields
        { key: "join_date", label: "Join Date" },
      ],
      show: true,
      is_sup: false,
      items: [],
      currentPage: 1,
      perPage: 1,
      rows: 0,
      previous: "",
      next: "",
      postdata: {
        is_birth: false,
        // todate: "",
        // number: "",
        // account: "",
        // trx: "",
        // total_amount: "",
      },
      member_data: null,
    };
  },
  computed: {
    // ...mapGetters(["allMemebers"]),
    // ...mapState(["member"]),
  },
  mounted() {},
  methods: {
    // ...mapActions(["getDatas"]),

    getusers() {
      var userdata = JSON.parse(localStorage.getItem("userData"));
      this.is_result = false;
      if (!this.postdata == "") {
        this.$http
          .post(`/dtm/list/?page=${this.rows || 1}`, this.postdata)
          .then((response) => {
            this.items = response.data.results;
            this.rows = response.data.count;
            this.next = response.data.next;
            this.previous = response.data.previous;
            this.show = false;
          })
          .catch((error) => console.log(error));
        // this.$http
        //   .get(`/dtm/reg/pay/userDash/${this.user}/`)
        //   .then((response) => {
        //     var data = response.data.account;
        //     this.statisticsItems[0].title =
        //       data.total_tran + "/" + data.this_month_tran;
        //     this.statisticsItems[1].title =
        //       data.total_reg + "/" + data.this_month_reg;
        //     this.statisticsItems[2].title = `৳` + data.this_month_amount;
        //     this.statisticsItems[3].title = `৳` + data.panding_amount;
        //     this.total = `৳` + data.total_amount;
        //   })
        //   .catch((error) => console.log(error));
        this.is_data = true;
      } else {
        let variant = "danger";
        this.$bvToast.toast("Query Data is Required", {
          title: `Error`,
          variant,
          solid: true,
        });
      }
    },
  },
  created() {
    this.getusers();
    // this.getDatas();
    // this.$http.get('/dtm/member/').then(response => console.log(response)).catch(error => console.log(error))
    var userdata = JSON.parse(localStorage.getItem("userData"));
    this.is_sup = userdata.is_sup;
  },
};
</script>